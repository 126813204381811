/**
 * @generated SignedSource<<f8c6cca5721210059481bc138f02dca3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateUserPasswordInput = {
  clientMutationId?: string | null;
  password: string;
  rowId: any;
};
export type CreateUserModalUpdateUserPasswordMutation$variables = {
  input: UpdateUserPasswordInput;
};
export type CreateUserModalUpdateUserPasswordMutation$data = {
  readonly updateUserPassword: {
    readonly user: {
      readonly hasPassword: boolean | null;
      readonly id: string;
      readonly updatedAt: any;
    } | null;
  } | null;
};
export type CreateUserModalUpdateUserPasswordMutation = {
  response: CreateUserModalUpdateUserPasswordMutation$data;
  variables: CreateUserModalUpdateUserPasswordMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateUserPasswordPayload",
    "kind": "LinkedField",
    "name": "updateUserPassword",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasPassword",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updatedAt",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateUserModalUpdateUserPasswordMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateUserModalUpdateUserPasswordMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "adbd7d421473cf5dfc9e9c5acb93c99d",
    "id": null,
    "metadata": {},
    "name": "CreateUserModalUpdateUserPasswordMutation",
    "operationKind": "mutation",
    "text": "mutation CreateUserModalUpdateUserPasswordMutation(\n  $input: UpdateUserPasswordInput!\n) {\n  updateUserPassword(input: $input) {\n    user {\n      hasPassword\n      id\n      updatedAt\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0e7204cc40474c086fa4f11b10447839";

export default node;
