/**
 * @generated SignedSource<<cc20c310577d38134cd073c502afa7a4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LeadForm_query$data = {
  readonly referralPartners: {
    readonly nodes: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
      readonly referralPartnerSources: {
        readonly nodes: ReadonlyArray<{
          readonly id: string;
          readonly name: string;
          readonly rowId: any;
        }>;
      };
      readonly rowId: any;
    }>;
  } | null;
  readonly users: {
    readonly nodes: ReadonlyArray<{
      readonly firstName: string;
      readonly lastName: string;
      readonly rowId: any;
    }>;
  } | null;
  readonly " $fragmentType": "LeadForm_query";
};
export type LeadForm_query$key = {
  readonly " $data"?: LeadForm_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"LeadForm_query">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Literal",
  "name": "orderBy",
  "value": "NAME_ASC"
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LeadForm_query",
  "selections": [
    {
      "alias": null,
      "args": [
        (v0/*: any*/)
      ],
      "concreteType": "ReferralPartnersConnection",
      "kind": "LinkedField",
      "name": "referralPartners",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ReferralPartner",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            (v1/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/),
            {
              "alias": null,
              "args": [
                {
                  "kind": "Literal",
                  "name": "filter",
                  "value": {
                    "isActive": {
                      "equalTo": true
                    }
                  }
                },
                (v0/*: any*/)
              ],
              "concreteType": "ReferralPartnerSourcesConnection",
              "kind": "LinkedField",
              "name": "referralPartnerSources",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ReferralPartnerSource",
                  "kind": "LinkedField",
                  "name": "nodes",
                  "plural": true,
                  "selections": [
                    (v1/*: any*/),
                    (v2/*: any*/),
                    (v3/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": "referralPartnerSources(filter:{\"isActive\":{\"equalTo\":true}},orderBy:\"NAME_ASC\")"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "referralPartners(orderBy:\"NAME_ASC\")"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "leadExists": false
          }
        },
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": [
            "FIRST_NAME_ASC",
            "LAST_NAME_ASC"
          ]
        }
      ],
      "concreteType": "UsersConnection",
      "kind": "LinkedField",
      "name": "users",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "firstName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lastName",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "users(filter:{\"leadExists\":false},orderBy:[\"FIRST_NAME_ASC\",\"LAST_NAME_ASC\"])"
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "5ac59bfd0753a74c8ba64f83edba567c";

export default node;
