/**
 * @generated SignedSource<<3cb8e658ab076440bd79b9fc0276f456>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type LeadsSource = "BREAST_PUMP_ORDER" | "FRIEND" | "HEALTH" | "HEALTH_PLAN" | "OTHER" | "REFERRAL_PARTNER" | "SEARCH" | "SOCIAL" | "%future added value";
export type LeadsStatus = "ACTIVE" | "CONVERTED" | "INACTIVE" | "NEW" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type LeadForm_lead$data = {
  readonly convertedAt: any | null;
  readonly optInAt: any | null;
  readonly optInMethod: string | null;
  readonly referralPartnerId: any | null;
  readonly referralPartnerSourceId: any | null;
  readonly slug: string | null;
  readonly source: LeadsSource;
  readonly status: LeadsStatus;
  readonly userId: any;
  readonly " $fragmentType": "LeadForm_lead";
};
export type LeadForm_lead$key = {
  readonly " $data"?: LeadForm_lead$data;
  readonly " $fragmentSpreads": FragmentRefs<"LeadForm_lead">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LeadForm_lead",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "referralPartnerId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "referralPartnerSourceId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "source",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "optInAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "optInMethod",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "convertedAt",
      "storageKey": null
    }
  ],
  "type": "Lead",
  "abstractKey": null
};

(node as any).hash = "9fca04714082e285e99040c80713e0a4";

export default node;
