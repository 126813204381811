/**
 * @generated SignedSource<<5199a80e8b8a1198a824e930ebaa7d12>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateUserPaymentMethodModal_user$data = {
  readonly rowId: any;
  readonly " $fragmentSpreads": FragmentRefs<"UserPaymentMethodForm_user">;
  readonly " $fragmentType": "CreateUserPaymentMethodModal_user";
};
export type CreateUserPaymentMethodModal_user$key = {
  readonly " $data"?: CreateUserPaymentMethodModal_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreateUserPaymentMethodModal_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreateUserPaymentMethodModal_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rowId",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserPaymentMethodForm_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "85658c6e294dae1f3f2dd1a0df4aa770";

export default node;
