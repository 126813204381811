/**
 * @generated SignedSource<<000a198f93f757dbbe56b4e6d9eb6fa7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateUserModal_query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"UserForm_query">;
  readonly " $fragmentType": "CreateUserModal_query";
};
export type CreateUserModal_query$key = {
  readonly " $data"?: CreateUserModal_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreateUserModal_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreateUserModal_query",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserForm_query"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "cb81273c263d19fa52d2a57c9207335d";

export default node;
